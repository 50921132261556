import { createI18n } from 'vue-i18n/index';

import esMessagesExecution from '~/i18n/execution/es.json';
import itMessagesExecution from '~/i18n/execution/it.json';
import ptMessagesExecution from '~/i18n/execution/pt.json';

import esMessagesSuite from '~/i18n/suite/es.json';
import itMessagesSuite from '~/i18n/suite/en.json';
import ptMessagesSuite from '~/i18n/suite/pt.json';

import esMessagesWatMonitor from '~/i18n/watmonitor/es.json';
import itMessagesWatMonitor from '~/i18n/watmonitor/it.json';
import ptMessagesWatMonitor from '~/i18n/watmonitor/pt.json';

import enMessagesGeographic from '~/i18n/geographic/en.json';
import esMessagesGeographic from '~/i18n/geographic/es.json';
import frMessagesGeographic from '~/i18n/geographic/fr.json';
import nlMessagesGeographic from '~/i18n/geographic/nl.json';
import itMessagesGeographic from '~/i18n/geographic/it.json';
import ptMessagesGeographic from '~/i18n/geographic/pt.json';

import enMessages from '~/i18n/communities/en-US.json';
import esMessages from '~/i18n/communities/es-ES.json';
import frMessages from '~/i18n/communities/fr-FR.json';
import nlMessages from '~/i18n/communities/nl-BE.json';
import itMessages from '~/i18n/communities/it-IT.json';
import ptMessages from '~/i18n/communities/pt-PT.json';

import { keys, merge } from 'lodash';

const messages = {
  en: merge(enMessages, enMessagesGeographic),
  es: merge(esMessages, esMessagesExecution, esMessagesGeographic, esMessagesWatMonitor, esMessagesSuite),
  fr: merge(frMessages, frMessagesGeographic),
  nl: merge(nlMessages, nlMessagesGeographic),
  it: merge(itMessages, itMessagesExecution, itMessagesGeographic, itMessagesWatMonitor, itMessagesSuite),
  pt: merge(ptMessages, ptMessagesExecution, ptMessagesGeographic, ptMessagesWatMonitor, ptMessagesSuite),
};

export default createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages: messages
});
