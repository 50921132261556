import ApiService from '@/services/api.service';
import { find } from 'lodash';

export const FETCH_NEXT_QUESTION_BLOCK = 'FETCH_NEXT_QUESTION_BLOCK';
export const FETCH_NEXT_QUESTION_BLOCK_PREVIEW = 'FETCH_NEXT_QUESTION_BLOCK_PREVIEW';
export const START_TEST_EXEC = 'START_TEST_EXEC';
export const START_PREVIEW_EXEC = 'START_PREVIEW_EXEC';
export const FETCH_TESTER_COUNTRY = 'FETCH_TESTER_COUNTRY';
export const ADD_TEST_QUESTION = 'ADD_TEST_QUESTION';
export const ADD_TEST_QUESTION_PREVIEW = 'ADD_TEST_QUESTION_PREVIEW';
export const FETCH_TEST_EXECUTION = 'FETCH_TEST_EXECUTION';
export const FETCH_TEST_PREVIEW_TOKEN = 'FETCH_TEST_PREVIEW_TOKEN';


const actions = {
  [FETCH_NEXT_QUESTION_BLOCK] (context, payload) {
    return ApiService
      .get('/execution/' + payload.testId +'/nextQuestionBlock')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      })
  },
  [FETCH_NEXT_QUESTION_BLOCK_PREVIEW] (context, payload) {
    let headers = {headers:{}}
    headers.headers['tesVisToken'] = payload.token
    return ApiService
      .get('/execution/public/' + payload.hash +'/nextQuestionBlock',null,headers)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      })
  },
  [START_TEST_EXEC] (context, payload) {
    return ApiService
      .post('/execution/' + payload.testId,payload.data)
  },
  [START_PREVIEW_EXEC] (context, payload) {
    let headers = {headers:{}}
    headers.headers['visToken'] = payload.token
    return ApiService
      .post('/execution/' + payload.hash + '/publicPreview',payload.data,headers)
  },
  [FETCH_TESTER_COUNTRY] (context, payload) {
    return ApiService
      .get('https://ipinfo.io/json',null, {
        'withCredentials': false,
        'Access-Control-Allow-Credentials': false,
      })
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      })
  },
  [ADD_TEST_QUESTION] (context, payload) {
    return ApiService
      .post('/execution/test/' + payload.testId + '/task/' + payload.taskNum + '/question/' + payload.questionId, payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      })
  },
  [ADD_TEST_QUESTION_PREVIEW] (context, payload) {
    let headers = {headers:{}}
    headers.headers['tesVisToken'] = payload.token
    return ApiService
      .post('/execution/public/' + payload.hash + '/task/' + payload.taskNum + '/question/' + payload.questionId, payload.data, headers)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      })
  },
  [FETCH_TEST_EXECUTION] (context, payload) {
    return ApiService
      .get('/execution/' + payload.testId)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      })
  },
  [FETCH_TEST_PREVIEW_TOKEN] (context, payload) {
    return ApiService
      .get('/visitorToken')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      })
  },
  async UPLOAD_IMAGE_URL(context, payload) {
    return ApiService
      .postFile('/file',payload.formData, {})
  },
};

const mutations = {
};

export default {
  actions,
};
